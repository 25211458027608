import { api } from "../../../../services/api/axios";

export class NotaApi {
    static async gerar(formData) {
        try {
            //vai ser um post pra /xml
            //request do tipo multipart/form-data
            const response = await api.post('/xml', formData, { responseType: 'blob' });
            return response;
        } catch (error) {
            console.error('Error:', error);
            return error;
        }
    }
}