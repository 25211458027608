import { NotaApi } from "./api/NotaApi";
export class NotaService {
    static async gerar(formData) {
        try {
            const response = await NotaApi.gerar(formData);
            return response;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
}