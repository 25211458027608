import { ArrowCircleLeft } from "@phosphor-icons/react";
import Input from "../../components/Input";
import{
    Container,
    ContainerRow,
    ContainerCollumContent,
    BoxForm,
    Title,
    Button,
    BoxContent,
    FooterItem,
    BoxFooter
} from "../../styles/shared";


import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { NotaService } from "./services/NotaService";
import { Toaster, toast } from 'sonner'

function Nota() {
    const navigate = useNavigate();
    const [arquivo, setArquivo] = useState(null);
    const [numeroInicial, setNumeroInicial] = useState(0);
    const [serie, setSerie] = useState('');
    const [aliquota, setAliquota] = useState('');
    const [loteInicial, setLoteInicial] = useState('');
    const downloadNota = (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'nota.zip');
        document.body.appendChild(link);
        link.click();
    }

    const handleGerarNota = async () => {
        const formData = new FormData();
        formData.append('arquivo', arquivo);
        formData.append('numeroInicial', numeroInicial);
        formData.append('serie', serie);
        formData.append('aliquota', aliquota);
        formData.append('loteInicial', loteInicial);
        try {
            const response = await NotaService.gerar(formData);
            downloadNota(response);
            toast.success('Nota gerada com sucesso');
            // toast.promise(await NotaService.gerar(formData), {
            //     loading: 'Gerando nota...',
            //     success: (response) => {
            //         downloadNota(response);
            //         return 'Nota gerada com sucesso';
            //     },
            //     error: 'Erro ao gerar nota',
            // });
        } catch (error) {
            console.error('Error:', error);
            toast.error('Erro ao gerar nota');
        }
    };
  return (
    <Container>
        <ContainerRow>
            <ContainerCollumContent>
                <BoxForm>
                    <Title>Gerar Nota</Title>
                    <BoxFooter>
                    <FooterItem>
                        <Button onClick={() => navigate(-1)}>
                            <ArrowCircleLeft />
                            Voltar
                        </Button>
                    </FooterItem>
                    </BoxFooter>
                    <BoxContent>
                        <Input
                            label="Arquivo CSV"
                            type="file"
                            onChange={(e) => setArquivo(e.target.files[0])}
                            accept=".csv"
                        />
                        <Input palsceholder="Digite o número inicial" label="Número Inicial" type='number' value={numeroInicial} onChange={(e) => setNumeroInicial(e.target.value)}/>
                        <Input placeholder="Digite o valor de série" type="text" label="Série" onChange={(e) => setSerie(e.target.value)}/>
                        <Input placeholder="Digite o valor de alíquota" type="text" label="Alíquota" onChange={(e) => setAliquota(e.target.value)}/>
                        <Input placeholder="Digite o valor de lote inicial" type='number' value={loteInicial} label="Lote Inicial" onChange={(e) => setLoteInicial(e.target.value)}/>
                        <Button onClick={() => handleGerarNota()}>Gerar</Button>
                    </BoxContent>
                </BoxForm>
            </ContainerCollumContent>
        </ContainerRow>
        <Toaster position="top-right" richColors duration={1000 * 2.5}/>
    </Container>
  )
}

export default Nota